<mat-form-field floatLabel="always" [style]="videoTextArea.inlineStyle" appearance="outline">
  <mat-label class="custom-form-caption-label">{{videoTextArea.caption}}</mat-label>
  <textarea [(ngModel)]="videoTextArea.content"
            [maxlength]="videoTextArea.maxChars === 0 ? null : videoTextArea.maxChars!"
            [rows]="16"
            (ngModelChange)="videoTextArea.content = $event"
            matInput></textarea>
  <div class="preview-badge" matBadge="PREVIEW" matBadgeColor="accent" matBadgeSize="medium"
       matBadgePosition="below before">
    <span class="preview-badge-description" hidden>Diese Vorschau ist nicht zu 100% akkurat.Es handelt sich um eine ungefähre Annäherung an die spätere Videotextausspielung.</span>
  </div>
  <app-character-counter *ngIf="videoTextArea.maxCharsWarning && videoTextArea.maxChars"
                         [maxChars]="videoTextArea.maxChars"
                         [maxCharsWarning]="videoTextArea.maxCharsWarning"
                         [charSequenceLength]="videoTextArea!.content!.length"></app-character-counter>
</mat-form-field>
