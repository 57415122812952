export const gatewayUrl = "https://dmx4-api-gateway-dev.gcp.cloud.hr.de"

export const environment = {
  production: false,
  enableAuth: true,
  terminalApiUrl: `${gatewayUrl}/api/wetter/terminal`,
  messagingApiUrl: `${gatewayUrl}/api/wetter/messaging/terminal`,
  // apiEndpoint: "https://dmx4-api-gateway-dev.gcp.cloud.hr.de",
  auth: {
    clientId: 'fb20fde3-1fa0-43c7-8e9c-fca7e7b29073', // Application (client) ID from the app registration
    authority: 'https://login.microsoftonline.com/daaae532-0750-4b35-8f3f-fdd6ba4c86f0', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    redirectUri: 'https://wetter-terminal-dev.gcp.cloud.hr.de',
    scope: ['api://3cb3e179-b17f-4ffe-a3ce-1c0127597a18/access_as_user']
  },
  firebaseConfig: {
    apiKey: "AIzaSyAziQD_S0PTCncqfaD8WiaDtIN-8LWWFyw",
    authDomain: "wetter-dts-dev-207755492.firebaseapp.com",
    databaseURL: "https://wetter-dts-dev-207755492.firebaseio.com",
    projectId: "wetter-dts-dev-207755492",
    storageBucket: "wetter-dts-dev-207755492.appspot.com",
    messagingSenderId: "505725237924",
    appId: "1:505725237924:web:d0bfbef7288d5bd24e0431",
    vapidKey: "BLtU2EyT5jpXs0YwTKCUV45EwDhrs5YChkW4JkwKMnRJdorXAj4c5e0TT4tx3kXhzUtLK37yVYmiCNvA-XOOCo4"
  }
};
