import {Component, Input} from '@angular/core';
import {VideoTextArea} from "../../../../model/layout.model";
import { CharacterCounterComponent } from '../character-counter/character-counter.component';
import { NgIf } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-video-text-area',
    templateUrl: './video-text-area.component.html',
    styleUrls: ['./video-text-area.component.scss', '../text-area/text-area.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, MatBadgeModule, NgIf, CharacterCounterComponent]
})
export class VideoTextAreaComponent {

  @Input() videoTextArea!: VideoTextArea;
  constructor() {
  }


}
